<template>
  <div>
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="text"
            label="项目"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            label="内容"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getBulk7Details} from '@/api/eChnenergyTask'

export default {
  name: 'Bulk4',
  data() {
    return {
      tableData: undefined
    }
  },
  mounted() {
    document.title = '招标信息池-建发'
    getBulk7Details({id: this.$route.params.id}).then(res => {
      console.log(res)
      this.tableData = [
        {text: '项目类型', value: res[0].bidCategoryName},
        {text: '所属公司', value: res[0].bidCompanyName},
        {text: '项目编号', value: res[0].bidId},
        {text: '标题', value: res[0].bidTitle},
        {text: '招标类型', value: res[0].bidType},
        {text: '联系人', value: res[0].contacts},
        {text: '起运地', value: res[0].departure},
        {text: '目的地', value: res[0].destination},
        {text: '电子邮箱', value: res[0].email},
        {text: '货物品种', value: res[0].goods},
        {text: '联系电话', value: res[0].phone},
        {text: '船期要求', value: res[0].shippingSchedule},
        {text: '规格', value: res[0].specification},
        {text: '起止时间', value: res[0].startAndEndTime},
        {text: '吨位', value: res[0].tonnage},
        {text: '说明', value: res[0].explain},
      ]
    })
  },
  methods: {}
}
</script>

<style scoped>

</style>
